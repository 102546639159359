.cta-button-layout {
    background-color: #eee;
    color: #000d1f;
    position: relative;
}
.cta-button-layout.dark {
    background-color: #000d1f;
    color: #eee;
}
.cta-button-layout.green {
    background-color: #f8f4f0;
    color: #043b3b;
}
.cta-button-layout .rafflebtn--layout {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 350ms ease-in-out;
}
.cta-button-layout .rafflebtn--layout > .cta-countdown {
    padding: 12px 6px;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
}
.cta-button-layout .rafflebtn--layout > .cta-countdown p {
    color: #000d1f;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}
.cta-button-layout .rafflebtn--layout > .button-block {
    width: 100%;
    padding: 10px;
    background: linear-gradient(rgba(255, 255, 255, 1) 0%, #cacaca 99.14%);
}
.cta-button-layout .rafflebtn--layout > .button-block button {
    border-radius: 8px;
}
.cta-button-layout .rafflebtn--layout > .button-block.countdown {
    width: 100%;
}
.cta-button-layout .rafflebtn--layout > .button-block.countdown button {
    width: 100%;
    height: 50px;
}
.cta-button-layout .rafflebtn--layout.hide {
    transform: translateY(200px);
}
@media screen and (min-width: 769px) {
    .cta-button-layout .rafflebtn--layout {
        display: none;
   }
}
