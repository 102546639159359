.hero-info-item__cols {
  /* grid-template-columns: auto;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 4px;*/
  width: 100%; 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

@media (max-width: 420px) {
  .hero-info-item__cols {
    grid-column-gap: 6px;
    align-items: flex-start;
    margin-top: 8px;
  }
}

.hero-info-date {
  /* max-width: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  b {
    font-weight: 800;
  }
}

@media (max-width: 420px) {
  .hero-info-date .rui-subtitle-text {
    font-size: 12px;
    line-height: 1.2;
    margin-top: 8px;
  }
}

.hero-info-date:first-child .rui-subtitle-text {
  max-width: 100%;
}

.hero-block-info .main-slider .swiper-container {
  padding-bottom: 44px;
}

@media (max-width: 670px) {
  .hero-block-info .main-slider .swiper-container {
    padding-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .hero-block-info .main-slider .swiper-container {
    padding-bottom: 20px;
  }
}

@media (max-width: 620px) {
  .hero-info-item {
    min-height: 255px;
    padding: 16px 12px;
  }
  .hero-info-item .hero-info-item__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
}

@media (max-width: 420px) {
  .hero-info-item {
    min-height: 235px;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
    padding: 8px 12px;
    align-items: flex-start;
  }
  .hero-info-item .hero-info-item__title {
    margin-top: 8px;
  }
  .hero-info-item .rui-subtitle-text {
    font-size: 12px;
    line-height: 1.2;
  }
  .hero-info-item .hero-info-item__icon {
    margin-bottom: 0px;
  }
}

.hero-info-date {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* .hero-info-date:nth-child(1) {
  max-width: 100%;
  flex: 0 0 auto;
} */

.video-block-heading {
  p {
    font-family: "ABC Gravity", sans-serif;
    font-size: 58px;
    line-height: 75.08px;
    font-weight: 900;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .video-block-heading {
    p {
      font-size: 44px;
      line-height: 56.96px;
    }
  }
}

@media screen and (max-width: 560px) {
  .video-block-heading {
    p {
      font-size: 20px;
      line-height: 1.2;
    }
  }
}

.video-block-badge {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 56px;
  right: 56px;
}

.video-block-badge svg {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 760px) {
  .video-block-badge {
    width: 120px;
    height: 120px;
    top: 11px;
    right: 50px;
  }
}

@media screen and (max-width: 640px) {
  .video-block-badge {
    width: 80px;
    height: 80px;
    right: 15px;
  }
}

@media screen and (max-width: 500px) {
  .video-block-badge {
    top: 50px;
  }
}