.loader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  margin: auto;
}
.loader.green-bg span {
  color: #f3f3f3;
}
.loader.green-bg > div {
  border-color: #f3f3f3 transparent transparent transparent;
}
.loader > div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #043b3b transparent transparent transparent;
}
.loader > div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader > div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader > div:nth-child(3) {
  animation-delay: -0.15s;
}
.loader.small {
  width: 20px;
  height: 20px;
}
.loader.small > div {
  border-width: 3px;
}
.loader span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 15px);
  color: #043b3b;
  font-weight: 600;
}
.loader-wrapper {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
