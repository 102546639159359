.header {
  position: sticky;
  top: 0;
  padding-bottom: 37px;
  margin-bottom: -37px;
  transition: transform 0.3s;
  z-index: 33;
  filter: drop-shadow(2px -4px 6px #000000);
  overflow: hidden;
}
.header.hide {
  transform: translateY(-150%);
}
.header__grid {
  min-height: 80px;
  display: grid;
  grid-template-columns: 1fr 290px 1fr;
  padding: 16.5px 0;
  background-color: var(--color-primary-white);
}
@media screen and (max-width: 1199px) {
  .header__grid {
    padding: 0;
    display: flex;
    justify-content: center;
    min-height: unset;
    height: 40px;
  }
}
.header__top {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}
@media screen and (min-width: 1200px) {
  .header__top {
    display: none;
  }
}
.header__left {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
@media screen and (max-width: 1199px) {
  .header__left {
    display: none;
  }
}
.header__btn-wrap {
  margin-right: 28px;
  flex-shrink: 0;
}
.header__btn-wrap button {
  width: 28px;
  height: 16px;
}
.header__nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
@media screen and (max-width: 960px) {
  .header__nav {
    display: none;
  }
}
.header__nav ul {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
@media screen and (max-width: 1199px) {
  .header__nav ul {
    display: none;
  }
}
.header__nav li {
  padding: 12.5px 0;
}
.header__nav a {
  font-family: 'Nunito Sans';
  color: #002323;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  border-bottom: 1px solid transparent;
  transition: all 100ms ease-out;
}
.header__nav a:hover {
  border-bottom: 1px solid #043b3b;
}
.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo img {
  width: 290px;
  height: 40px;
  object-fit: contain;
}
@media screen and (max-width: 1199px) {
  .header__logo {
    grid-column: 2 / 3;
  }
}
@media screen and (max-width: 960px) {
  .header__logo {
    padding: 20px 0;
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }
}
@media screen and (max-width: 560px) {
  .header__logo {
    padding: 0;
  }
  .header__logo img {
    height: 28px;
  }
}
.header-trustpilot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 472px;
  height: 71px;
  left: 50%;
  bottom: 2px;
  z-index: -10;
  transform: translateX(-50%);
  overflow: hidden;
  background: url(../../assets/images/redesign-v2/trustpilot-bg.svg) no-repeat center / contain;
}
@media screen and (max-width: 960px) {
  .header-trustpilot {
    left: 51%;
  }
}
@media screen and (max-width: 560px) {
  .header-trustpilot {
    left: 52%;
  }
}
.header-trustpilot .trust-wrapper {
  position: relative;
  bottom: -12px;
  left: -5px;
  transform: scale(0.8);
}
.header-countdown-block h3 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 20px;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .header-countdown-block__devider {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .header-countdown-block {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
  }
  .header-countdown-block h3 {
    font-family: 'ABC Gravity';
    font-size: 12px;
    font-weight: 900;
  }
}
