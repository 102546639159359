.text {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13.5px;
    fill: #111;
}
.footer-logo-box .rotation-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 206.5px;
    height: 206.5px;
    background-color: #fff;
    border-radius: 50%;
}
@media screen and (max-width: 1080px) {
    .footer-logo-box .rotation-box {
      width: 140px;
      height: 140px;
    }
  }
.footer-logo-box .rotation-box .no-rotation-image {
    z-index: 2;
    border-radius: 50%;
}
@media screen and (max-width: 1080px) {
    .footer-logo-box .rotation-box .no-rotation-image {
        width: 112px;
        height: 112px;
   }
}
.footer-logo-box .rotation-box .rounded-text {
    position: absolute;
    text-transform: uppercase;
    color: #043b3b;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    animation: spin 15s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
   }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
@keyframes rotate-circle {
    to {
        transform: rotate(1turn);
   }
}
.footer-logo-box {
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
}
.footer-logo-box img {
    position: absolute;
}
.footer-logo-box img {
    position: absolute;
}
.circle-box {
    position: relative;
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-box .logo-box {
    position: absolute;
    width: 167px;
    height: 167px;
    border-radius: 50%;
}
.circle-box .text-box {
    position: absolute;
    width: 100%;
    height: 100%;
    animation-name: rotate-circle;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.circle-box .text-box span {
    position: absolute;
    left: 50%;
    font-size: 13px;
    transform-origin: 0 87px;
}
