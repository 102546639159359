@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~raffle-house-uikit/dist/cjs/index.css';
@import '~swiper/swiper.min.css';
@import '~swiper/components/navigation/navigation.min.css';
@import '~swiper/components/pagination/pagination.min.css';

:root {
  --color-primary-green: #043b3b;
  --color-primary-white: #ffffff;
  --color-primary-sand: #f8f4f0;
  --color-primary-lime: #dee965;
  --color-primary-grey: #2e2e2e;
  --color-primary-pink: #e2bcc0;
  --color-accent-coral: #ff6f61;
}

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  color: var(--color-primary-grey);
  background-color: var(--color-primary-grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
.slick-slider img {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 1258px) {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 1258px) {
  .container {
    padding: 0 1rem;
  }
}

a {
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-family: 'Nunito Sans';
}

.root-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.root-content .root-routes {
  flex-grow: 1;
  position: relative;
  background-color: var(--color-primary-sand);
}
.page-section {
  padding-top: 120px;
  padding-bottom: 120px;
}
