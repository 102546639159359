.mainscreen {
  text-align: center;
  color: var(--color-primary-white);
}
.mainscreen.page-section {
  padding-top: 87.5px;
  padding-bottom: 0;
}
.mainscreen__container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}
.mainscreen__container h1 {
  max-width: 800px;
  margin: 0 auto;
}
.ticket-container .mainscreen__text {
  margin-bottom: 55px;
}
.mainscreen .divider {
  display: none;
}
