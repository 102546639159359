.mainscreen {
  text-align: center;
  color: var(--color-primary-white);
}
.mainscreen.page-section {
  padding-top: 87.5px;
  padding-bottom: 0;
}
.mainscreen__container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}
.mainscreen__container h1 {
  max-width: 800px;
  margin: 0 auto;
}
.mainscreen__text {
  /* margin-bottom: 55px; */
  margin-bottom: 0px;
}
.mainscreen__text.mt16 {
  margin-top: 16px;
  margin-bottom: 56px;
}
.mainscreen .divider {
  display: none;
}

.dream-draw__image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}
.dream-draw__image img {
  max-width: 100%;
  aspect-ratio: 96 / 54;
  height: auto;

  @media screen and (max-width: 576px) {
    aspect-ratio: 512 / 384;
  }
}

.timeline-block .timeline-block-header {
  padding: 110px 20px 60px;
}

.charity-block .charities-list .left-side {
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
}

.footer-margin {
  height: 100px;

  @media screen and (max-width: 960px) {
    height: 0px;
  }
}