.ticket-card.ticket-card_white {
  --bg-color: var(--color-primary-white);
  --border-color: var(--color-primary-gray);
  --text-color: var(--color-primary-gray);
  --text-color-2: var(--color-primary-pink);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
  --discount-bg-color: var(--color-accent-coral);
  --discount-border-color: var(--color-primary-coral);
  --discount-text-color: var(--color-primary-white);
  --shadow-color: rgba(255, 255, 255, 0.7);
}
.ticket-card.ticket-card_green {
  --bg-color: var(--color-primary-green);
  --border-color: var(--color-primary-white);
  --text-color: var(--color-primary-white);
  --text-color-2: var(--color-primary-pink);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
  --discount-bg-color: var(--color-accent-coral);
  --discount-border-color: var(--color-primary-coral);
  --discount-text-color: var(--color-primary-white);
  --shadow-color: rgba(4, 59, 59, 0.7);
}
.ticket-card {
  width: 200px;
  min-height: 233px;
  display: flex;
  position: relative;
  justify-self: center;
  border-radius: 28px;
  color: var(--text-color);
  transition: all 0.2s linear;
}
@media screen and (max-width: 480px) {
  .ticket-card {
    width: 100%;
    min-height: 184px;
  }
}
.ticket-card:hover {
  box-shadow: 0px 4px 10px 3px var(--shadow-color);
}
.ticket-card.ticket-card_white .ticket-card__grid {
  background-color: var(--bg-color);
}
.ticket-card.ticket-card_green .ticket-card__grid {
  background-color: var(--bg-color);
}
.ticket-card.most-popular::before {
  content: 'MOST POPULAR';
  width: 100%;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 0px 42px;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-primary-green);
  background-color: var(--color-primary-pink);
  border-radius: 20px 20px 0 0;
}
.ticket-card.lowest-ticket-price::before {
  content: 'LOWEST TICKET PRICE';
  width: 100%;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 0px 42px;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-primary-green);
  background-color: var(--color-primary-lime);
  border-radius: 20px 20px 0 0;
}
@media screen and (max-width: 480px) {
  .ticket-card.most-popular,
  .ticket-card.lowest-ticket-price {
    margin-top: 48px;
  }
  .ticket-card.most-popular::before,
  .ticket-card.lowest-ticket-price::before {
    width: 200px;
  }
}

.ticket-card__grid {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 12px;
  border-radius: 28px;
  z-index: 1;
  color: var(--text-color);
}
.ticket-card__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
@media screen and (max-width: 480px) {
  .ticket-card__grid {
    padding: 16px;
  }
  .ticket-card__main {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    flex-wrap: wrap;
    margin-top: 16px;
    /* min-height: 102px; */
  }
}
.ticket-card .discount {
  position: absolute;
  top: -8px;
  right: -5px;
  /* width: 90px; */
  width: auto;
  height: 29px;
  padding: 0 9px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: var(--discount-text-color);
  background: var(--discount-bg-color);
  border: 1px solid var(--discount-border-color);
}
@media screen and (max-width: 480px) {
  .ticket-card .discount {
    /* width: 100px; */
    /* height: 36px; */
    /* font-size: 18px; */
    /* line-height: 34px; */
  }
}

.ticket-card .price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-height: 118px; */
  margin: 12px auto 16px;
  font-family: ABC Gravity;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 1.16px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .ticket-card .price {
    flex-shrink: 0;
    min-height: unset;
    /* margin-top: 8px; */
    margin: 0;
  }
}
.ticket-card .price > .per-ticket {
  text-align: center;
  /* Body/Regular */
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: initial;
}
.ticket-card .price > .discounted {
  font-style: normal;
  font-weight: 600;

  text-align: center;
  font-size: 38px;
  margin-bottom: 10px;
  text-decoration: line-through;
  text-decoration-color: #ff6f61;

  /* display: none; */
}
.ticket-card .details {
  width: 100%;
  /* min-height: 84px; */
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.details-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.details-rows:nth-of-type(2) {
  color: var(--text-color-2);
}
.details-row {
  font-size: 24px;
  line-height: 32.74px;
  font-weight: 900;
  letter-spacing: 0.4px;
  line-height: normal;
  text-align: center;
}
.details-row.discount-tickets {
  font-size: 20px;
  line-height: 27.28px;
  text-decoration: line-through;
  text-decoration-color: #ff6f61;
  font-weight: 400;
}
.ticket-card__btn {
  padding: 12px 10px;
  border-radius: 50px;
  background-color: var(--button-bg-color);
  font-family: ABC Gravity;
  font-size: 16px;
  line-height: 20.71px;

  font-weight: 900;
  letter-spacing: 0.54px;
  color: var(--button-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
@media screen and (max-width: 480px) {
  .ticket-card__btn {
    min-width: 150px;
    flex-grow: 1;
  }
}
/* .ticket-card > .info > .details p:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 20px;
  width: 100%;
  text-align: center;

  color: #09101d;
}
.ticket-card > .info > .details p:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding-top: 10px;
  width: 100%;
  text-align: center;

  color: #09101d;
} */
/* .ticket-card > .info > .details p.orange:last-child { */
/* margin-top: 10px; */
/* font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 20px;
  width: 100%;
  text-align: center;
  background-color: #f48202;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .ticket-card > .info > .details p.space {
  color: #09101d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.ticket-card > .info > .details p.gray:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 20px;
  width: 100%;
  text-align: center;

  margin-top: 5px;
  background-color: #545d69;
  color: #fff;
  padding: 5px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .ticket-card > .info > .details p > span.mobile-plus {
  display: none;
} */
/* .ticket-card > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin: 10px;

  width: auto;
  height: 56px;

  background: #f48202;
  border-radius: 8px 8px 8px 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  outline: none;
  border: none;

  color: #eee;
  cursor: pointer;
  transition: all 200ms ease-out;
}

.ticket-card > button > .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.ticket-card > button:is(:hover) {
  background: #f48202;
}
.ticket-card > button:is(:active) {
  background: #f48202;
}
.ticket-card > button:is(:disabled) {
  cursor: initial;
  background: #09101d;
} */

/* @media screen and (max-width: 576px) {
  .ticket-card {
    margin: 0 10px;
    width: 100%;
    min-height: 141px;
    height: auto;

    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(20, 72, 253, 0.24);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .ticket-card:last-child {
    margin-bottom: 0px;
  }
  .ticket-card > .info {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px 10px;
  }
  .ticket-card > .info > .price {
    width: 30%;
  }
  .ticket-card > .info > .details {
    width: 60%;
  }
  .ticket-card > .info > .details p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    text-align: left;

    color: #f48202;
    padding: 5px 12px;
  }
  .ticket-card > .info > .details p:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-top: 10px;
    width: 100%;
    text-align: left;

    color: #f48202;
  }
  .ticket-card > .info > .details p.orange:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-top: 5px;
    width: 100%;
    text-align: left;

    color: #f48202;
    display: initial;
    background-color: transparent;
  }
  .ticket-card > .info > .details p.space {
    display: none;
  }

  .ticket-card > .info > .details p.gray:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-top: 5px;
    width: 100%;
    text-align: left;

    color: #f48202;
    display: initial;
    background-color: transparent;
  }
  .ticket-card > .info > .details p > span.mobile-plus {
    display: inline;
  }
  .ticket-card > .info > .divider {
    margin: 0 auto;
    width: 1px;
    height: 100%;
    background-color: #c4c4c4;
  }
} */
