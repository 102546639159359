.subscription-card.subscription-card_white {
  --border-color: var(--color-primary-gray);
  --text-color: var(--color-primary-green);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
}
.subscription-card.subscription-card_green {
  --border-color: var(--color-primary-lime);
  --text-color: var(--color-primary-lime);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
}
.subscription-card {
  width: 218px;
  min-height: 318px;
  padding: 20px 12px;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}
@media screen and (max-width: 480px) {
  .subscription-card {
    width: 100%;
    min-height: 262px;
    margin-top: 42px;
  }
}
.subscription-tickets-count {
}
.subscription-tickets-count:nth-child(2) {
  color: #e2bcc0;
}
.subscription-card .price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 118px;
  margin-top: 5px;
  text-align: center;
}
.subscription-card .price .price-big {
  display: flex;
  align-items: center;
  font-family: ABC Gravity;
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 1.16px;
  text-transform: uppercase;
  line-height: 75px;
}
@media screen and (max-width: 480px) {
  .subscription-card .price .price-big {
    align-items: flex-start;
  }
}
.subscription-card .price .price-big span {
  font-family: 'Anton';
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .subscription-card .price .price-big span {
    position: relative;
    top: 16px;
    letter-spacing: 0.4px;
    line-height: 1;
    text-transform: lowercase;
  }
}
.subscription-card .price .price-small {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  line-height: 27px;
}
.subscription-card__icon {
  position: absolute;
  top: -47px;
  left: -2px;
  width: 70px;
  height: 66px;
  color: var(--border-color);
}
.subscription-card__icon svg path {
  fill: currentColor;
}
@media screen and (max-width: 480px) {
  .subscription-card__icon {
    top: -42px;
  }
}
.subscription-card__icon svg {
  width: 100%;
  height: 100%;
}
.subscription-card .details {
  width: 100%;
  min-height: 84px;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.subscription-card__btn {
  padding: 12px 8px;
  border-radius: 50px;
  background-color: var(--button-bg-color);
  font-family: ABC Gravity;
  font-size: 27px;
  font-weight: 900;
  letter-spacing: 0.54px;
  color: var(--button-text-color);
}
@media screen and (max-width: 480px) {
  .subscription-card__btn {
    font-size: 16px;
    flex-grow: 1;
  }
}
/* .subscription-card > .info > .details p:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 20px 0 20px;
  text-align: center;
  width: 100%;

  color: #09101d;
} */
/* .subscription-card > .info > .details p:not(:first-child) {
  margin-top: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  color: #fff;
  background-color: #f48202;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
} */
/* .subscription-card > .info > .details p.space {
  color: #09101d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
} */
/* .subscription-card > .info > .details p.gray {
  margin-top: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  width: 100%;

  color: #fff;
  background-color: #545d69;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
} */
/* .subscription-card > .info > .details p > span.mobile-plus {
  display: none;
} */
/* .subscription-card > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin: 10px;
  width: auto;
  height: 56px;

  background: #f48202;
  border-radius: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  outline: none;
  border: none;

  color: #eee;
  cursor: pointer;
  transition: all 200ms ease-out;
}

.subscription-card > button > .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.subscription-card > button:is(:hover) {
  background: #f48202;
}
.subscription-card > button:is(:active) {
  background: #f48202;
}
.subscription-card > button:is(:disabled) {
  cursor: initial;
  background: #f48202;
} */

/* @media screen and (max-width: 576px) {
  .subscription-card {
    margin: 0 10px;
    width: 100%;
    min-height: 141px;
    height: auto;

    background: #fff;
    box-shadow: 0px 4px 10px rgba(20, 72, 253, 0.24);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .subscription-card > .info {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px 10px;
  }
  .subscription-card > .info > .price {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 21px;
  }
  .subscription-card > .info > .details {
    width: 60%;
  }
  .subscription-card > .info > .details p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-top: 10px;
    text-align: left;
    width: 100%;

    color: #f48202;
  }

  .subscription-card > .info > .details p:not(:first-child) {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    width: 100%;

    color: #f48202;
    display: initial;
    background-color: transparent;
  }
  .subscription-card > .info > .details p.space {
    display: none;
  }
  .subscription-card > .info > .details p > span.mobile-plus {
    display: inline;
  }
  .subscription-card > .info > .divider {
    margin: 0 auto;
    width: 1px;
    height: 100%;
    background-color: #c4c4c4;
  }
} */
