.ticket-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 20px;
}
@media screen and (max-width: 480px) {
  .ticket-container {
    padding: 32px 10px;
  }
}
.ticket-text-container {
  max-width: 864px;
  margin: 0 auto 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ticket-text-container .subtitle-text{
  @media screen and (max-width: 560px) {
    max-width: 300px;
  }
}
@media screen and (max-width: 480px) {
  .ticket-text-container  {
    margin-bottom: 40px;
  }
}
.ticket-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: center;
  column-gap: 26px;
  row-gap: 74px;
}
@media screen and (max-width: 1400px) {
  .ticket-list {
    grid-template-columns: repeat(3, auto);
  }
}
@media screen and (max-width: 767px) {
  .ticket-list {
    grid-template-columns: repeat(2, auto);
  }
}
@media screen and (max-width: 480px) {
  .ticket-list {
    grid-template-columns: 100%;
    row-gap: 26px;
  }
}
/* @media screen and (max-width: 1360px) {
  .ticket-list {
    max-width: 650px;
    gap: 10px;
  }
} */
/* @media screen and (max-width: 650px) {
  .ticket-list {
    max-width: 576px;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 576px) {
  .ticket-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .ticket-list {
    gap: 10px;
  }
} */
