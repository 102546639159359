.section-wrapper {
    background-color: var(--color-primary-white);
}
.section-wrapper.bg-1 {
    background-color: var(--color-primary-sand);
}
.section-wrapper.bg-2 {
    background-color: var(--color-primary-green);
}
.section-wrapper.bg-3 {
    background-color: var(--color-primary-white);
}
.section-wrapper.bg-4 {
    background-color: var(--color-primary-lime);
}
