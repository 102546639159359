/* .timeline-block {
  position: relative;
  width: 100%;
  padding: 120px 66px;
  overflow: hidden;
}
.timeline-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1560px;
  height: 1560px;
  border-radius: 50%;
  background-color: var(--color-primary-white);
  opacity: 0.1;
  z-index: 1;
}
.timeline-block-header {
  position: relative;
  max-width: 960px;
  margin: 0 auto 80px;
  padding: 0;
  text-align: center;
} */
@media screen and (max-width: 1080px) {
  .timeline-block-header {
    margin-bottom: 140px;
  }
}
.timeline-block__title {
  max-width: 844px;
  margin: 0px auto;
}
@media screen and (max-width: 960px) {
  .timeline-block-header > h3 {
    color: #dee965;
  }
}
/* @media screen and (max-width: 768px) {
  .timeline-block {
    padding: 60px 16px;
  }
} */
@media screen and (max-width: 480px) {
  .timeline-block::before {
    top: -345px;
  }
  .timeline-block__title .primary-title.color-1 {
    color: var(--color-primary-lime);
  }
}

.timeline-block-badge {
  margin-top: 20px;
  padding: 10px 35px;
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #ff6f61;
  border-radius: 12px;
}

@media screen and (max-width: 768px) {
  .timeline-block-badge {
    margin-top: 24px;
    padding: 10px 4px;
    font-size: 18px;
    line-height: 24px;
  }
}

.timeline-block__main .horizontal-timeline {
  margin-bottom: -10px;
}
.horizontal-timeline .horizontal-timeline-item__date {
  min-height: 56px;
}

.timeline-point.checked {
  background: url("../../assets/images/redesign-v2/timeline/checked-icon.svg")
    no-repeat center center / contain;
    svg {
        display: none;
    }
}

.timeline-point.checked::before {
  display: none;
}

.timeline-block-container .rui-subtitle-text{
  max-width: 750px;
  margin: 0 auto;
}