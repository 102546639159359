@media screen and (max-width: 1410px) {
    .wrapCustom, .homeContentText .wrapCustom, .trustpilotWrap .wrapCustom {
        width: 100%;
   }
    .homeContentText .wrapCustom {
        padding: 0 30px;
   }
    .home-cover-container {
        padding: 96px 30px 96px;
   }
    .ticketsWrap {
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 30px;
   }
    .homeTicket {
        min-width: 329px;
   }
    .stepsItem-wrap {
        padding: 0 30px;
   }
    .stepsItem {
        margin-right: 20px;
   }
    .stepsItem:last-child {
        margin-right: 0;
   }
    .home-container {
        height: 576px;
   }
}
.wrapCustom, .homeContentText .wrapCustom, .trustpilotWrap .wrapCustom {
    width: 100%;
}
.trustpilotWrap {
    margin: 0 auto;
    padding: 30px 15px 40px;
}
@media (max-width: 576px) {
    .trustpilotWrap {
        padding-bottom: 0;
   }
}
.trustpilot-link {
    display: block;
    width: 100%;
    text-align: center;
    color: #004944;
}
.wrapCustom iframe > #wrapper-top {
    background-color: crimson;
}
