@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HKGrotesk-Regular/HKGrotesk-Regular.eot');
  src: url('../fonts/HKGrotesk-Regular/HKGrotesk-Regular.eot?#iefix')
    format('embedded-opentype');
  src: url('../fonts/HKGrotesk-Regular/HKGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Regular/HKGrotesk-Regular.woff') format('woff'),
    url('../fonts/HKGrotesk-Regular/HKGrotesk-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HKGrotesk-Medium/HKGrotesk-Medium.eot');
  src: url('../fonts/HKGrotesk-Medium/HKGrotesk-Medium.eot?#iefix')
    format('embedded-opentype');
  src: url('../fonts/HKGrotesk-Medium/HKGrotesk-Medium.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Medium/HKGrotesk-Medium.woff') format('woff'),
    url('../fonts/HKGrotesk-Medium/HKGrotesk-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HKGrotesk-SemiBold/HKGrotesk-SemiBold.eot');
  src: url('../fonts/HKGrotesk-SemiBold/HKGrotesk-SemiBold.eot?#iefix')
    format('embedded-opentype');
  src: url('../fonts/HKGrotesk-SemiBold/HKGrotesk-SemiBold.woff2')
      format('woff2'),
    url('../fonts/HKGrotesk-SemiBold/HKGrotesk-SemiBold.woff') format('woff'),
    url('../fonts/HKGrotesk-SemiBold/HKGrotesk-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HKGrotesk-Bold/HKGrotesk-Bold.eot');
  src: url('../fonts/HKGrotesk-Bold/HKGrotesk-Bold.eot?#iefix')
    format('embedded-opentype');
  src: url('../fonts/HKGrotesk-Bold/HKGrotesk-Bold.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Bold/HKGrotesk-Bold.woff') format('woff'),
    url('../fonts/HKGrotesk-Bold/HKGrotesk-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Morton';
  src: url('../fonts/Morton-Bold/Morton-Bold.woff2') format('woff2');
  src: url('../fonts/Morton-Bold/Morton-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Morton';
  src: url('../fonts/Morton-Medium/Morton-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* ABC Gravity */
@font-face {
  font-family: 'ABC Gravity';
  src: url('../fonts/Gravity/ABCGravity-Normal.otf') format("opentype"),
    url('../fonts/Gravity/ABCGravity-Normal.woff') format('woff'),
    url('../fonts/Gravity/ABCGravity-Normal.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
/* Nunito Sans */
@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

/* Anton */
@font-face {
  font-family: 'Anton';
  src: url('../fonts/Anton/Anton-Regular.eot') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Anton';
  src: url('../fonts/Anton/Anton-Regular.eot');
  src: url('../fonts/Anton/Anton-Regular.eot?#iefix')
    format('embedded-opentype');
  src: url('../fonts/Anton/Anton-Regular.woff2') format('woff2'),
    url('../fonts/Anton/Anton-Regular.woff') format('woff'),
    url('../fonts/Anton/Anton-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

/* * {
  box-sizing: border-box;
  font-family: 'HKGrotesk', sans-serif;
} */

*::-webkit-scrollbar {
  width: 10px;
  background-color: var(--color-primary-green);
}
*::-webkit-scrollbar-thumb {
  background: var(--color-primary-lime);
  border-radius: 8px;
}

/* .container {
  margin: 0 auto;
} */

/* @media screen and (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    width: 960px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 720px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: 540px;
  }
}
@media screen and (max-width: 575px) {
  .container {
    width: 100%;
  }
} */
/* .highlight {
  color: #f48202;
} */
