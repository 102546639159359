.bottom-cta-button-mobile-container {
  /* position: fixed;
  bottom: 0;
  z-index: 20; */
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #043b3b;
  text-align: center;
 /* Mobile/Headings/H3 */
  font-family: 'ABC Gravity', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  /* cursor: pointer; */
}
.bottom-cta-button-mobile-container.variant-1 {
  background: #e2bcc0;
  box-shadow: 0px -4px 0px 0px #be9095;
}
.bottom-cta-button-mobile-container.variant-2 {
  background: #dee965;
  box-shadow: 0px -4px 0px 0px #aab348;
}
@media screen and (max-width: 960px) {
  .bottom-cta-button-mobile-container {
    display: flex;
 }
}
@media screen and (max-width: 560px) {
  .bottom-cta-button-mobile-container {
    display: flex;
    /* height: 55px; */
    font-size: 22px;
    flex-direction: column-reverse;
  }
}

.mobile-bottom-button-countdown p {
  color: #043b3b;
  font-family: "ABC Gravity", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  padding: 16px 0px 14px 0px;
}

@media screen and (max-width: 560px) {
  .mobile-bottom-button-countdown p {
    padding: 16px 0px 14px 0px;
  }
}