.mobile-bottom-button-countdown {
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .mobile-bottom-button-countdown {
    display: flex;
 }
}
