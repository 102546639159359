.countdown-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.infoModalTimes {
  display: flex;
  align-items: center;
}

.infoModalTimes .modalTimer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 2px 0 0;
}
.infoModalTimes .modalTimer span {
  font-size: 20px;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0.4px;
}
@media screen and (max-width: 1199px) {
  .infoModalTimes .modalTimer span {
    font-family: ABC Gravity;
    font-size: 12px;
    font-weight: 900;
  }
}

