.mobile-header-countdown {
  display: none;
  width: 100%;
  padding: 14px 10px 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #e2bcc0;
}
.mobile-header-countdown p {
  color: #043b3b;
  /* Mobile/Headings/H6 */
  font-family: 'ABC Gravity', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
}
.mobile-header-countdown p > span {
  color: #043b3b;
  /* Mobile/Headings/H6 */
  font-family: 'ABC Gravity', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .mobile-header-countdown {
    display: flex;
  }
}
