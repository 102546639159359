.cookie-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999999999;
    opacity: 1;
    transition: all 0.3s ease;
    padding: 15px;
    text-align: center;
    color: #dbe0df;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
}
.cookie-container button.rafflebtn {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 10px;
}
@media screen and (max-width: 678px) {
    .cookie-container button.rafflebtn {
        margin-bottom: 3px;
   }
    .cookie-container button.rafflebtn:last-child {
        margin-bottom: 0;
   }
}
.cookie-container button.rafflebtn a {
    color: #043b3b;
    white-space: nowrap;
}
.cookie-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cookie-wrapper-new {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cookie-container-new {
    position: relative;
    width: 95%;
    max-width: 960px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    z-index: 999999999;
    opacity: 1;
    transition: all 0.3s ease;
    padding: 32px;
    text-align: center;
}
.cookie-container-new .cookie-close-button {
    position: absolute;
    right: 5px;
    top: 10px;
}
.cookie-container-new > .cookie-text {
    color: #043b3b;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 24px;
}
@media screen and (max-width: 678px) {
    .cookie-container-new > .cookie-text {
        font-size: 16px;
        line-height: 20px;
   }
}
.cookie-container-new button.rafflebtn {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
    line-height: 22px;
    padding: 6px 10px;
    height: 50px;
    background: #dee965;
    border-color: #dee965;
    color: #043b3b;
    font-family: 'ABC Gravity';
    border-radius: 10px;
}
@media screen and (max-width: 678px) {
    .cookie-container-new button.rafflebtn {
        font-size: 14px;
        line-height: 20px;
   }
    .cookie-container-new button.rafflebtn:last-child {
        margin-bottom: 0;
   }
}
.cookie-container-new button.rafflebtn.readmore {
    border-color: #dee965;
    background-color: transparent;
}
.cookie-container-new button.rafflebtn.readmore > a {
    color: #043b3b;
}
.cookie-container-new button.rafflebtn a {
    color: #043b3b;
    white-space: nowrap;
}
