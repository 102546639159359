.footerWrapper-bg {
  position: relative;
  border-top: 1px solid #ddd;
  color: #6a7b8d;
  background: transparent;
}
.footerWrapper-bg a {
  color: #eee;
}
.footerWrapper-bg--dark {
  border-top: 1px solid #ddd;
  background: #043b3b;
}
.footerWrapper-bg--dark a {
  color: #eee;
}
.footerWrapper-bg--green {
  border-top: 1px solid #167878;
  background: #043b3b;
}
.footerWrapper-bg--green a {
  color: #eee;
}
.footerWrapper-bg--green a:hover {
  color: #dee965;
}
.containerFooter {
  width: 100%;
  max-width: 1440px;
  padding: 0 30px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .containerFooter.more-padding {
    padding: 0 30px 150px;
  }
}
.footerWrapper {
  display: flex;
  justify-content: space-between;
  min-height: 236px;
  padding: 1.875rem 0;
}
@media screen and (max-width: 960px) {
  .footerWrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: 2fr 2fr;
    padding: 1.3rem 0 0 0;
  }
}
@media screen and (max-width: 490px) {
  .footerWrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
}
.footer-list-block h3 {
  color: #dee965;
  font-family: 'ABC Gravity', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 20px;
}
.footer-list-block span {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.footer-list-block ul li {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
}
@media screen and (max-width: 490px) {
  .footer-list-block ul {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }
}
.footer-list-block .trust-wrapper {
  display: none;
}
@media screen and (max-width: 560px) {
  .footer-list-block .trust-wrapper {
    margin-top: 10px;
    display: block;
  }
}
.companyDesc {
  width: 95%;
  color: #fff;

  /* Body/Regular */
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 576px) {
  .companyDesc {
    width: 100%;
    display: grid;
    margin-top: 30px;
  }
}
.companyDesc > h3 {
  color: #fff;

  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 8px 0;
}
.companyDesc > span {
  @media screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 22px;
  }
}
.socialIcons {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.socialIcons .sponsorsImage {
  display: none;
}
.socialIcons > .socialIconsImages > h3 {
  font-size: 14px;
  margin-bottom: 5px;
}
.socialIcons > .socialIconsImages > a {
  color: #eee;
}
.socialIcons > .socialIconsImages > a img {
  display: block;
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.socialIcons > .socialIconsImages > ul {
  display: flex;
  align-items: center;
  width: 50%;
}
.socialIcons > .socialIconsImages > ul a {
  background-color: #fff 1a;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
.socialIcons > .socialIconsImages > ul a:hover {
  box-shadow: 0px 0px 5px 0px #dee96580;
}
.socialIcons > .socialIconsImages > ul li:not(:first-child) {
  margin-left: 1.75rem;
}
.socialIcons a {
  padding-right: 1.75rem;
}
.socialIcons a:last-child {
  padding-right: 0;
  padding-bottom: 0;
}
.footer-image-container {
  width: 100%;
  height: 200.5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.footer-image-container .footer-image {
  position: absolute;
  top: -102px;
}
@media screen and (max-width: 1080px) {
    .footer-image-container .footer-image {
        top: -75px;
    }
}
@media screen and (max-width: 960px) {
  .footer-image-container {
    display: none;
  }
}
.footer-divider-container {
  padding: 48px 40px;
}
.footer-divider {
  width: 100%;
  height: 1px;
  background-color: #167878;
}
.footer-sponsors-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 40px 40px;
}
.footer-sponsors-block a {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}
@media screen and (max-width: 960px) {
  .footer-sponsors-block {
    padding: 0 40px 110px 40px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .footer-sponsors-block a {
    text-align: center;
    font-size: 14px;
  }
  .footer-sponsors-block a:first-child {
    order: 2;
  }
  .footer-sponsors-block a:last-child {
    order: 3;
  }
  .footer-sponsors-block .trust-wrapper {
    order: 1;
  }
}
@media screen and (max-width: 560px) {
  .footer-sponsors-block > .trust-wrapper {
    display: none;
  }
}
.footerContactLinks {
  display: flex;
  justify-content: center;
  margin: 0.625rem 0.5rem 0;
}
@media screen and (max-width: 576px) {
  .footerContactLinks {
    width: 100%;
    justify-content: space-between;
    margin: 0.625rem 0 0;
  }
}
.footerContactLinks div {
  display: flex;
  flex-direction: column;
}
.footerContactLinks div:not(:last-child) {
  padding-right: 2rem;
}
.footerContactLinks div a {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  padding: 5px;
}
.footerContactLinks div a:last-child {
  margin-right: 0;
}
.sponsors-name {
  padding-right: 14px;
  font-size: 1rem;
  font-weight: 400;
}
.sponsors-name span {
  position: relative;
  font-weight: 700;
}
.sponsors-name span::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -14px;
  width: 12px;
  height: 12px;
  background: url(../../assets/images/copyright.svg) no-repeat center center /
    contain;
}

.footerWrapper-bg {
  position: relative;
}
@media screen and (max-width: 960px) {
  .footerWrapper-bg {
    padding-top: 60px;
  }
}
.footer__logos {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 50px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 0px 30px 0px;
  margin: -60px auto 0;
  max-width: 1440px;
}
@media screen and (max-width: 960px) {
  .footer__logos {
    margin: 0px auto 0;
    column-gap: 30px;
  }
}

@media screen and (max-width: 650px) {
  .footer__logos {
    justify-content: center;
  }
}

@media screen and (max-width: 560px) {
  .footer__logos {
    padding: 0px 20px 0px;
    margin: -50px auto 20px;
  }
}

.footer-begambleaware {
  display: block;
  width: 280px;
}
.footer-begambleaware svg {
  width: 100%;
}
@media screen and (max-width: 700px) {
  .footer-begambleaware {
    width: 230px;
  }
}

.footer-fundraisingregulator {
  display: block;
  width: 280px;
}

.footer-fundraisingregulator img {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .footer-fundraisingregulator {
    width: 250px;
  }
}

.socialIcons__whatsapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-weight: inherit;
  font-size: inherit;
  margin-bottom: 30px;
}
.socialIcons__whatsapp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  transition: 0.2s;
}

.socialIcons__whatsapp:hover .socialIcons__whatsapp-icon {
  box-shadow: 0px 0px 5px 0px #dee96580;
}