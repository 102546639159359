.ticket-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 20px;
}
@media screen and (max-width: 480px) {
  .ticket-container {
    padding: 32px 10px;
  }
}
.ticket-text-container {
  max-width: 864px;
  margin: 0 auto 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ticket-text-container .subtitle-text {
  @media screen and (max-width: 560px) {
    max-width: 300px;
  }
}
@media screen and (max-width: 480px) {
  .ticket-text-container {
    margin-bottom: 40px;
  }
}
.ticket-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-items: center;
  justify-content: center;
  column-gap: 26px;
  row-gap: 74px;
}
@media screen and (max-width: 1400px) {
  .ticket-list {
    grid-template-columns: repeat(auto-fill, 270px);
  }
}
@media screen and (max-width: 480px) {
  .ticket-list {
    /* grid-template-columns: 100%; */
    row-gap: 26px;
  }
}
/* @media screen and (max-width: 1360px) {
    .ticket-list {
      max-width: 650px;
      gap: 10px;
    }
  } */
/* @media screen and (max-width: 650px) {
    .ticket-list {
      max-width: 576px;
      gap: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .ticket-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }
    .ticket-list {
      gap: 10px;
    }
  } */
.ticket-card.double {
  max-width: 270px;
  width: 100%;
}
.ticket-card.double .ticket-card__main {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}
.ticket-card.double .ticket-card__grid .ticket-card-header > .header-divider {
  margin: 0;
}
.ticket-card.double.most-popular::before {
  top: -65px;
  padding: 20px 8px 55px;
  box-sizing: border-box;
}
.ticket-card.double.lowest-ticket-price::before {
  top: -65px;
  padding: 20px 8px 55px;
  box-sizing: border-box;
}
.ticket-card.double .ticket-card__grid {
  justify-content: space-between;
}
.ticket-card.double .price {
  margin: 0;
  justify-content: space-between;
}
.ticket-card.double .ticket-card__grid {
  width: 270px;
}
.ticket-card__grid .ticket-card-header {
  width: 100%;
  padding: 0;
}
.ticket-card__grid .ticket-card-header .header-prizes-block {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.ticket-card__grid .ticket-card-header .header-prizes-block .prize-item {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 0 3px;
}
.ticket-card__grid .ticket-card-header .header-prizes-block .prize-item p {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner {
  position: absolute;
  top: -49px;
  right: -15px;
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner
  > svg
  path {
  fill: #ff6f61;
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner
  .banner-text-box {
  overflow: hidden;
  position: absolute;
  top: 52%;
  left: 52%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 65px;
  height: 55px;
  transform: translate(-45%, -50%) rotateZ(-15deg);
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner
  .banner-text-box
  p {
  color: #fff;
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner
  .banner-text-box
  p:first-child {
  overflow: hidden;
  max-width: 55px;
  margin-right: 3px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}
.ticket-card__grid
  .ticket-card-header
  .header-prizes-block
  .prize-item
  .banner
  .banner-text-box
  p:last-child {
  max-width: 65px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  margin-top: 4px;
  font-weight: 300;
  font-size: 5px;
}
.ticket-card__grid .ticket-card-header .header-prizes-block .vertical-divider {
  height: 100%;
  width: 1px;
  background-color: #043b3b;
}
.ticket-card__grid .header-divider {
  margin: 18px 0 5px 0;
  width: 100%;
  height: 1px;
  background-color: #043b3b;
}
.ticket-card__grid .ticket-card-header > .header-divider {
  margin: 18px 0 5px 0;
  width: 100%;
  height: 1px;
  background-color: #043b3b;
}
@media screen and (max-width: 480px) {
  .ticket-card__btn {
    min-width: auto;
    flex-grow: 1;
  }
}
