.charity-block {
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 40px;
}
.charity-block .primary-title {
  text-transform: initial;
}
.charity-block .mobile-spacer {
  display: none;
}
.charity-logo {
  margin: 20px 30px 0px;
  width: 230px;
}

@media screen and (max-width: 960px) {
  .charity-logo {
    margin: 20px auto 0px;
  }
}
.charity-logo img {
  width: 100%;
}
@media screen and (max-width: 560px) {
  .charity-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .charity-block .mobile-spacer {
    display: inline-block;
  }
}
.charity-block .charities-list {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .charity-block .charities-list {
    margin-top: 40px;
  }
}
.charity-block .charities-list .left-side {
  width: 350px;
  margin-bottom: 28px;
}
.charity-block .charities-list .left-side p.title {
  /* color: #043b3b; */
  color: #2e2e2e;
  font-family: "ABC Gravity", sans-serif;
  /* Headings/H4 */
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.72px;
}
.charity-block .charities-list .left-side p.title span {
  color: #e2bcc0;
  /* Headings/H4 */
  font-family: "ABC Gravity";
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.72px;
}
.charity-block .charities-list .left-side p.sub {
  margin-top: 12px;
  /* color: #043b3b; */
  color: #2e2e2e;
  /* Body/Black */
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.charity-block .charities-list .left-side p.sub span {
  font-weight: 900;
}
@media screen and (max-width: 960px) {
  .charity-block .charities-list .left-side p.title {
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.72px;
  }
  .charity-block .charities-list .left-side p.title span {
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.72px;
  }
  .charity-block .charities-list .left-side p.sub {
    text-align: center;
  }
}
.charity-block .charities-list .right-side {
  width: calc(100% - 350px);
}
@media screen and (max-width: 960px) {
  .charity-block .charities-list .left-side p.title,
  .charity-block .charities-list .left-side p.title span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
}
@media screen and (max-width: 960px) {
  .charity-block .charities-list {
    flex-direction: column;
    justify-content: center;
  }
  .charity-block .charities-list .right-side {
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  .charity-block .charities-list .left-side p.sub {
    font-size: 16px;
    line-height: 22px;
  }
}
.charity-block .charity-buttons-group {
  margin-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.charity-block .charity-buttons-group button.charity-cta-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px 28px;
  border-radius: 50px;
  background: #dee965;
  color: #043b3b;
  text-align: center;
  font-family: "ABC Gravity", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.24px;
  margin-bottom: 20px;
  transition: all 200ms ease;
}
.charity-block .charity-buttons-group button.charity-cta-button svg {
  margin-left: 10px;
}
.charity-block .charity-buttons-group button.charity-cta-button svg {
  fill: #043b3b;
}
.charity-block .charity-buttons-group button.charity-cta-button svg circle {
  fill: #dee965;
}
.charity-block .charity-buttons-group button.charity-cta-button:hover {
  background-color: #043b3b;
  color: #dee965;
}
.charity-block .charity-buttons-group button.charity-cta-button:hover svg {
  fill: #dee965;
}
.charity-block
  .charity-buttons-group
  button.charity-cta-button:hover
  svg
  circle {
  fill: #043b3b;
}
@media screen and (max-width: 960px) {
  .charity-block .charity-buttons-group button.charity-cta-button {
    display: none;
  }
}
.charity-block .charity-buttons-group .learn-more-button {
  color: #043b3b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}
.charity-block .charity-buttons-group .learn-more-button svg {
  margin-left: 10px;
}
@media screen and (max-width: 960px) {
  .charity-block {
    padding-left: 0px;
  }
}
.charity-rounded-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.charity-rounded-image > img {
  margin-left: 0px;
  width: 90px;
  height: auto;
  object-fit: contain;
}
.charity-rounded-image > img.rounded {
  border-radius: 50%;
}
