.postal-card.postal-card_white {
  --bg-color: var(--color-primary-white);
  --border-color: var(--color-primary-green);
  --text-color: var(--color-primary-gray);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
  --shadow-color: rgba(255, 255, 255, 0.7);
}
.postal-card.postal-card_green {
  --bg-color: var(--color-primary-green);
  --border-color: var(--color-primary-white);
  --text-color: var(--color-primary-white);
  --button-bg-color: var(--color-primary-lime);
  --button-text-color: var(--color-primary-green);
  --shadow-color: rgba(4, 59, 59, 0.7);
}
.postal-card {
  width: 200px;
  min-height: 233px;
  padding: 20px 12px;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  color: var(--text-color);
  transition: all 0.2s linear;
}
@media screen and (max-width: 480px) {
  .postal-card {
    width: 100%;
    min-height: 183px;
    padding: 16px;
  }
}
.postal-card:hover {
  box-shadow: 0px 4px 10px 3px var(--shadow-color);
}
.postal-card.postal-card_white {
  background-color: var(--bg-color);
}
.postal-card.postal-card_green {
  background-color: var(--bg-color);
}
.postal-card__main {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .postal-card__main {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 28px;
    flex-wrap: wrap;
    min-height: 102px;
  }
}
.postal-card .price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-height: 118px; */
  margin-top: 5px;
  font-family: ABC Gravity;
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  line-height: 28.48px;
}
@media screen and (max-width: 480px) {
  .postal-card .price {
    width: 100%;
    min-height: unset;
    margin: 16px auto 16px auto;
    font-size: 27px;
    line-height: 34.95px;
  }
}
.postal-card .details {
  width: 100%;
  min-height: 77px;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
}
.postal-card__btn {
  padding: 12px 10px;
  border-radius: 50px;
  background-color: var(--button-bg-color);
  font-family: ABC Gravity;
  font-size: 16px;
  line-height: 20.71px;
  font-weight: 900;
  letter-spacing: 0.54px;
  color: var(--button-text-color);
}
@media screen and (max-width: 480px) {
  .postal-card .details {
    min-height: auto;
  }
  .postal-card__btn {
    flex-grow: 1;
  }
}
